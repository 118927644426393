// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useState } from 'react'
import './ExportMenu.css'
import ExportDialog from './ExportDialog'
import MedModal from '../../../../shared/MedModal'
import { useStructure } from '../../../../shared/contexts/StructureContext'

// Function based component, represents the export button, which is inserted
// in the selected data table. When this button is pressed, the export dialog
// compontent is shown in a react modal viewz
function ExportMenu({ selectedData }) {
  // Initialize the modal state
  const [showModal, setModalState] = useState(false)


  // Modify selectedData to have display name as key
  const attributeMapping = {};
  const variableCategories = useStructure()
  const sortedVariableCategories = {};

  for (const category in variableCategories) {
    // Sort each category's variables by `data-value`
    sortedVariableCategories[category] = [...variableCategories[category]].sort((a, b) => {
      return a['data-value'].localeCompare(b['data-value']);
    });
  }

  // Iterate through variableCategories to build the attributeMapping
  for (const category in variableCategories) {
    for (const varIndex in variableCategories[category]) {
      const variable = variableCategories[category][varIndex];
      const attributeName = variable['data-key'];
      const nicerName = variable['data-value'];

      attributeMapping[attributeName] = nicerName;
    }
  }

  // Use the attributeMapping to transform selectedData
  const transformedData = selectedData.map(item => {
    const transformedItem = {};

    for (const attributeName in item) {
      const nicerName = attributeMapping[attributeName] || attributeName;
      transformedItem[nicerName] = item[attributeName];
    }

    return transformedItem;
  });

  // Handler to close the modal
  const closeModal = () => setModalState(false)
  return (
    <>
      <button
        className="med-primary-solid med-bx-button med-data-button"
        onClick={() => setModalState(true)}
      >
        <i className="bx bxs-file-export med-button-image"></i>Export
      </button>
      <MedModal showModal={showModal} closeModal={closeModal}>
        <ExportDialog data={transformedData} variableCategories={sortedVariableCategories} attributeMapping={attributeMapping} onClose={closeModal} />
      </MedModal>
    </>
  )
}

export default ExportMenu
