// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'
import ErrorDialog from './components/ErrorDialog'
import SuccessDialog from './components/SuccessDialog'
import ErrorMessage from './components/ErrorMessage'
import RadioElement from './components/RadioElement'
import config from './../../../../config/config'

// Class based component which renders the export dialog
// It is passed some series of datapoints, and it allows the user
// to export these points in any of the available file types.
// A class is used instead of a function because of the extensive state.
class ExportDialog extends React.Component {
  constructor(props) {
    super(props)

    this.selectedAttributes = new Set()
    // Fill selectedAttributes with data-values from variableCategories
    Object.values(props.variableCategories || {}).forEach(attributes => {
      attributes.forEach(attr => {
        this.selectedAttributes.add(attr['data-value']);
      });
    });

    this.state = {
      dialogState: 'default',
      exportType: 'xlsx',
      customSeparator: '',
      onClose: props.onClose,
      errorMessage: '',
      selectedAttributes: this.selectedAttributes,
      isAttributeSelection: false,
    }

    this.selectedData = props.data;
    this.variableCategories = props.variableCategories || {};
    this.attributeMapping = props.attributeMapping

    // Error messages
    this.noExportType = 'No file type selected. Please select one of the above specified file types to be able to export the selected data.'
    this.noSeparator = 'No separator given. Please specify a separator to be used in the custom delimited file format to be able to export the selected data.';
    this.noAttributesSelected = 'Please select at least one variable to download.';

    this.closeDialog = this.closeDialog.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.exportData = this.exportData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAttributeSelection = this.handleAttributeSelection.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
  }

  // Toggle between export type and attribute selection views
  toggleView = () => {
    this.setState((prevState) => ({
      isAttributeSelection: !prevState.isAttributeSelection,
    }))
  }

  // Handler to close the dialog
  closeDialog() {
    this.state.onClose()
  }

  // Method used for handling when the user pressess the download button.
  // First it is checked if the current dialog state is valid, if so the
  // data is exported and downloaded, otherwise an error is displayed.
  handleDownload(event) {
    event.preventDefault();

    // Check if export type is set
    if (this.state.exportType === '') {
      this.setState({ errorMessage: this.noExportType });
      return;
    }

    // Check if custom separator is provided when custom export type is selected
    if (this.state.exportType === 'custom' && this.state.customSeparator === '') {
      this.setState({ errorMessage: this.noSeparator });
      return;
    }

    // Check if any attributes are selected
    if (this.state.selectedAttributes.size === 0) { // Assuming selectedAttributes is a Set
      this.setState({ errorMessage: this.noAttributesSelected }); // Set your error message
      return;
    }

    try {
      this.exportData();
    } catch {
      this.setState({ dialogState: 'error' });
      return;
    }

    this.setState({ dialogState: 'success' });
  }

  // Exports all the datapoints contained in the selected data, according to
  // the given user preference. It assumes that the current state of
  // user preference is valid
  exportData() {
    const { exportType, selectedAttributes } = this.state
    // Convert selectedAttributes Set to an array and sort it alphabetically
    // first default keys then alphabetically and all links / urls at the end
    function sortAttributes(selectedAttributes, config, attributeMapping) {
      // Extract main and secondary keys
      const mainKeys = (config.columns.main.default || []).map(key => attributeMapping[key]);
      const secondaryKeys = (config.columns.secondary.default || []).map(key => attributeMapping[key]);
      console.log(mainKeys)

      
      // Convert the Set to an array
      const attributesArray = Array.from(selectedAttributes);
    
      // Create a custom sorting function
      return attributesArray.sort((a, b) => {
        // Check if either attribute is in mainKeys
        const aMainIndex = mainKeys.indexOf(a);
        const bMainIndex = mainKeys.indexOf(b);
        if (aMainIndex !== -1 || bMainIndex !== -1) {
          // Prioritize mainKeys; non-mainKeys should be treated as "infinitely far"
          return (aMainIndex === -1 ? Infinity : aMainIndex) - (bMainIndex === -1 ? Infinity : bMainIndex);
        }
    
        // Check if either attribute is in secondaryKeys
        const aSecondaryIndex = secondaryKeys.indexOf(a);
        const bSecondaryIndex = secondaryKeys.indexOf(b);
        if (aSecondaryIndex !== -1 || bSecondaryIndex !== -1) {
          return (aSecondaryIndex === -1 ? Infinity : aSecondaryIndex) - (bSecondaryIndex === -1 ? Infinity : bSecondaryIndex);
        }
    
        // Check if either attribute ends with "link" or "_url"
        const isALink = a.includes('Link');
        const isBLink = b.includes('Link');
        if (isALink && !isBLink) return 1; // a goes after b
        if (!isALink && isBLink) return -1; // a goes before b
    
        // Fallback to alphabetical order
        return a.localeCompare(b);
      });
    }
    
    const sortedAttributes = sortAttributes(selectedAttributes, config, this.props.attributeMapping);

    // Filter the data to include only sorted selected attributes
    const filteredData = this.selectedData.map(item => {
      const filteredItem = {};

      sortedAttributes.forEach(attr => {
        if (item.hasOwnProperty(attr)) {
          filteredItem[attr] = item[attr];
        }
      });

      return filteredItem;
    });

    // Create worksheet
    const ws = XLSX.utils.json_to_sheet(filteredData);

    // Initialize data and file extension variables
    let data, extension;

    // Process export by file type
    if (exportType === 'xlsx') {

      // parse dates to excel supported dates instead of datetime
      Object.keys(ws).forEach(cell => {
        const cellValue = ws[cell].v
        if (this.isDateString(cellValue)) {
          // Convert the date string to a Date object
          const date = new Date(cellValue)
          // Set the date to a pure date without time
          date.setHours(0, 0, 0, 0)
          ws[cell].v = date
          ws[cell].t = 'd' // Set cell type to date
          ws[cell].z = 'dd/mm/yyyy' // Apply date format 'day/month/year'
        }
      })
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      extension = '.xlsx';
    } else if (exportType === 'custom') {
      const separator = this.state.customSeparator
      data = new Blob([XLSX.utils.sheet_to_csv(ws, { FS: separator })]);
      extension = '.txt';
    } else if (exportType === 'csv') {
      data = new Blob([XLSX.utils.sheet_to_csv(ws, { FS: ',' })]);
      extension = '.csv';
    } else if (exportType === 'tsv') {
      data = new Blob([XLSX.utils.sheet_to_csv(ws, { FS: '\t' })]);
      extension = '.tsv';
    } else if (exportType === 'ssv') {
      data = new Blob([XLSX.utils.sheet_to_csv(ws, { FS: ';' })]);
      extension = '.ssv';
    } else {
      throw new Error('Invalid export type');
    }

    const today = new Date()
    const filename = `EMRD_${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}${extension}`
    FileSaver.saveAs(data, filename);
  }

  // Helper function to check if a string is a date string
  isDateString(dateString) {
    return /^\d{4}-\d{2}-\d{2}$/.test(dateString)
  }

  handleChange(event) {
    const { type, value, id } = event.target;

    if (type === 'radio') {
      this.setState({ exportType: id })
    }

    if (type === 'text' && id === 'separator') {
      this.setState({ customSeparator: value })
    }
  }

  handleAttributeSelection(attribute) {
    this.setState(prevState => {
      const selectedAttributes = new Set(prevState.selectedAttributes);
      if (selectedAttributes.has(attribute)) {
        selectedAttributes.delete(attribute);
      } else {
        selectedAttributes.add(attribute);
      }
      return { selectedAttributes };
    });
  }

  toggleSelectAll() {
    const allAttributes = new Set();
    Object.values(this.variableCategories).forEach(category => {
      category.forEach(attr => allAttributes.add(attr['data-value']));
    });
    this.setState(prevState => ({
      selectedAttributes: prevState.selectedAttributes.size === allAttributes.size ? new Set() : allAttributes,
    }));
  }

  getDisplayText() {
    const selectedCount = this.state.selectedAttributes.size;
    const totalAttributesCount = Object.values(this.variableCategories || {})
      .flat().length;

    return selectedCount === totalAttributesCount
      ? 'All variables selected'
      : `${selectedCount}/${totalAttributesCount} variable${selectedCount !== 1 ? 's' : ''} selected`;
  }

  renderExportTypeUI() {
    var errorMessage = null
    if (this.state.errorMessage !== '') {
      errorMessage = <ErrorMessage message={this.state.errorMessage} />
    }

    const displayText = this.getDisplayText();

    return (
      <div className="med-export-dialog med-dialog">
        <i className="bx bxs-download" />
        <h1>Export Selected Data</h1>
        <h3>{`${this.selectedData.length} ${this.selectedData.length === 1 ? 'item' : 'items'} selected`}</h3>
        <h3>{displayText}</h3>
        <span className="med-description">
          Choose one of the file types below to export the selected data,
          or specify a custom delimited file type.
        </span>

        <div className="med-download-option-list">
          <RadioElement
            onChange={this.handleChange}
            name="radio"
            id="xlsx"
            value="Excel File (.xlsx)"
            checked={this.state.exportType === 'xlsx'}
          />
          <RadioElement
            onChange={this.handleChange}
            name="radio"
            id="csv"
            value="Comma Separated (.csv)"
            checked={this.state.exportType === 'csv'}
          />
          <RadioElement
            onChange={this.handleChange}
            name="radio"
            id="tsv"
            value="Tab Separated (.tsv)"
            checked={this.state.exportType === 'tsv'}
          />
          <RadioElement
            onChange={this.handleChange}
            name="radio"
            id="ssv"
            value="Semicolon Separated (.ssv)"
            checked={this.state.exportType === 'ssv'}
          />
          <RadioElement
            onChange={this.handleChange}
            name="radio"
            id="custom"
            value="Custom Separator:"
            checked={this.state.exportType === 'custom'}
          >
            <input
              onChange={this.handleChange}
              type="text"
              id="separator"
              className="med-separator-input-field med-text-input"
            />
          </RadioElement>
        </div>

        {errorMessage}

        <button onClick={this.toggleView} className="med-primary-solid">Select Variables</button>
        <button onClick={this.handleDownload} className="med-primary-solid accept">Download File</button>
        <button onClick={this.closeDialog} className="med-cancel-button">Cancel</button>
      </div>
    )
  }

  renderAttributeSelectionUI() {
    const displayText = this.getDisplayText();

    return (
      <div className="attribute-selection-ui med-dialog">
        <h2>Select Variables</h2>
        <span className="med-description">
          Choose which variables you would like to download. By default, all variables are downloaded.
        </span>

        <button onClick={this.toggleSelectAll} className="med-primary-solid">Select All / Deselect All</button>

        <div className="attribute-selection">
          {Object.entries(this.variableCategories || {}).map(([category, attributes]) => (
            <fieldset key={category}>
              <legend>{category}</legend>
              {attributes.map(attr => (
                <label key={attr['data-key']}>
                  <input
                    type="checkbox"
                    checked={this.state.selectedAttributes.has(attr['data-value'])}
                    onChange={() => this.handleAttributeSelection(attr['data-value'])}
                  />
                  <div className="checkbox-visual"></div>
                  {attr['data-value']}
                </label>
              ))}
            </fieldset>
          ))}
        </div>
        {/* Display the count of selected attributes or "All" */}
        <div className="selected-attributes-count">
          {displayText}
        </div>
        <button onClick={this.toggleView} className="med-primary-solid">Back to Export Menu</button>
      </div>
    )
  }

  render() {
    const { dialogState, isAttributeSelection } = this.state

    if (dialogState === 'success') return <SuccessDialog num={this.selectedData.length} onClick={this.closeDialog} />
    if (dialogState === 'error') return <ErrorDialog onClick={this.closeDialog} />

    return (
      isAttributeSelection ? this.renderAttributeSelectionUI() : this.renderExportTypeUI()
    )
  }
}

export default ExportDialog;